$opacity-steps: (
        "75": "75%",
        "50": "50%",
        "25": "25%",
);

@each $step, $prc in $opacity-steps {
  .opacity-#{$step}{
    opacity: #{$prc};
  }
}