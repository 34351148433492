$margin-steps: (
        "1": "0.25rem",
        "2": "0.5rem",
        "3": "1rem",
        "4": "1.5rem",
        "5": "3rem",
);

@each $step, $size in $margin-steps {
  .ms--#{$step}, .mx--#{$step}, .m--#{$step} {
    margin-left: -#{$size};
  }
  .me--#{$step}, .mx--#{$step}, .m--#{$step} {
    margin-right: -#{$size};
  }
  .mt--#{$step}, .my--#{$step}, .m--#{$step} {
    margin-top: -#{$size};
  }
  .mb--#{$step}, .my--#{$step}, .m--#{$step} {
    margin-bottom: -#{$size};
  }

}