/*body {*/
/*  margin: 0;*/
/*  padding: 0;*/
/*  box-sizing: border-box;*/
/*  background: #e4e4e4;*/
/*  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",*/
/*    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",*/
/*    sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*}*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}
label[aria-required="true"]:after{
  content: "*";
  color: var(--bs-danger);
}


::-webkit-scrollbar {
  width: 7px;
  background: #e9ecff;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  width: 7px;
  background: #aeb4d9;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #868ec0;
}

::-webkit-scrollbar-thumb:active {
  background: #abaec4;
}

.position-unset {
  position: initial!important;
}

:root{
  --hrm-top-menu-height: 76px;
}

.top-menu{
  /*z-index: 2000!important;*/
  height: var(--hrm-top-menu-height);
}

.offcanvas{
  z-index: 2001!important;
}

.top-menu .offcanvas:not(.show){
  height: calc(var(--hrm-top-menu-height) - var(--bs-navbar-padding-y) * 2);
}
.top-menu .offcanvas:not(.show) .nav-link {
  display: flex;
  flex-direction: column;
}

.top-menu .navbar-collapse.collapse.show .navbar-nav {
  max-height: 75vh;
}

.top-menu .user-menu .dropdown-menu{
  position: absolute;
}


.body{
  margin-top: var(--hrm-top-menu-height);
}

.dashboard{
  height: calc(100vh - var(--hrm-top-menu-height));
  overflow: auto;
}

.fixed-under-menu{
  top: var(--hrm-top-menu-height);
  right: 0;
  left: 0;
  z-index: 1030;
}

/**************************************************/
/*              SIZING UTILS                      */
/**************************************************/

.max-vw-100{
  max-width: 100vw;
}

.max-vh-100{
  max-height: 100vh;
}
